@import "variables";
@import "~bootstrap/scss/bootstrap";

.default-radius {
  border-radius: $default-radius;
}

.box-shadow {
  box-shadow: 1px 1px 14px 4px #eee;
}

body {
  font-family: 'Poor Story', 'Roboto Condensed', cursive;
  background: #fafafa;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: initial;
  border-radius: 0.25rem;
  box-shadow: 1px 1px 14px 4px #eee;
  border: 1px solid #eee;
}

.centered-container {
  min-height: 70vh;
  max-height: calc(90vh - $header);
  display: flex;
  align-items: center;
  justify-content: center;
}

header div {
  background: $primary;
  height: $header;
}


footer {
  width: 100%;
  position: relative;

  .fixed-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}